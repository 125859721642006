$(function() {
    $("#instagram").text("loading...");
    var html = '<ul class="instagram__list">';
    itemNum = '6'; // 表示数
    $.ajax({
        url: "/instagram.php",//PHPファイルのURL
        type:"POST",
        dataType: "json"
    }).done(function(data){
        $("#instagram").text("");
        var length = data.media.length;
        for (var i = 0; i < itemNum; i++) {
            var d = data.media.data[i];
            if (d.media_url.indexOf('mp4') == -1) {
                html += '<li class="instagram__item"><a href="' + d.permalink + '" target="_blank" class="wrap"><img src="' + d.media_url + '" alt=""></a></li>';
            } else {
                html += '<li class="instagram__item"><a href="' + d.permalink + '" target="_blank" class="wrap"><video src="'+ d.media_url +'"></video></a></li>';
            }
        }
        html += '</ul>';
    }).fail(function(){
        //通信失敗時の処理
        html = 'loading...';
    }).always(function(){
        //通信完了時の処理
        $("#instagram").html(html);
    });
});